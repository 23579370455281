export default defineAppConfig({
  productRelationshipLoaderPersoo: {
    persooAlternativesEnabled: true,
    persooBasketEnabled: true,
    persooLastViewedEnabled: true,
    persooNotFoundEnabled: true,
    persooPreBasketEnabled: true,
    apiKey: "vh0ouis9qu40h2holseh6t8s",
    dataLayerName: "dataLayerV2",
    persooEnabled: true,
    persooOfferIdAlternatives: "a8d7253dcf894e0db7dbcd31e8cc732a",
    persooOfferIdBasket: "cb1361adacc84f37891a11e77e7fb05e",
    persooOfferIdLastViewed: "bfb9d1a6ea93417585388debbafecd20",
    persooOfferIdNotFound: "f94e1aef6a6448418f6d7ae15db56be9",
    persooOfferIdPreBasket: "b46a4273a9614b5cb6e16b9e335315c8",
    persooPreBasketLimit: 6,
    persooNotFoundLimit: 6,
    persooName: "persoo"
  }
});