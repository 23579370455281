export default {
  orderModuleConfig: {
    deliveryGroupMapping: {
      pickup_place_cc: [
        {
          type: 'milestone',
          key: 'new',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.new.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.inprogress.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'inprogress_error',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.inprogress_error.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.inprogress_error.text',
          variant: 'error',
          parent: 'inprogress',
        },
        {
          type: 'connector',
          key: 'inprogress_backordered',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.inprogress_backordered.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.inprogress_backordered.text',
          variant: 'info',
          parent: 'inprogress',
        },
        {
          type: 'connector',
          key: 'packed',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.packed.label',
          text: '',
          variant: 'info',
          parent: 'inprogress',
        },
        {
          type: 'milestone',
          key: 'shipping',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.shipping.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'ready_for_pickup',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.ready_for_pickup.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.delivered.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.canceled.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.canceled.text',
          variant: 'error',
          parent: '',
        },
        {
          type: 'connector',
          key: 'canceled_expired',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.canceled_expired.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.canceled_expired.text',
          variant: 'error',
          parent: 'canceled',
        },
        {
          type: 'connector',
          key: 'canceled_returned',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.canceled_returned.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_cc.canceled_returned.text',
          variant: 'error',
          parent: 'canceled',
        },
      ],
      pickup_place_box: [
        {
          type: 'milestone',
          key: 'new',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.new.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'unpaid',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.unpaid.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.unpaid.text',
          variant: 'warning',
          parent: 'new',
        },
        {
          type: 'connector',
          key: 'inprogress_payment_complete',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.inprogress_payment_complete.label',
          text: '',
          variant: 'success',
          parent: 'new',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.inprogress.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'inprogress_error',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.inprogress_error.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.inprogress_error.text',
          variant: 'error',
          parent: 'inprogress',
        },
        {
          type: 'connector',
          key: 'inprogress_backordered',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.inprogress_backordered.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.inprogress_backordered.text',
          variant: 'info',
          parent: 'inprogress',
        },
        {
          type: 'connector',
          key: 'packed',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.packed.label',
          text: '',
          variant: 'info',
          parent: 'inprogress',
        },
        {
          type: 'milestone',
          key: 'shipping',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.shipping.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'ready_for_pickup',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.ready_for_pickup.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.delivered.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.canceled.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.canceled.text',
          variant: 'error',
          parent: '',
        },
        {
          type: 'connector',
          key: 'canceled_expired',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.canceled_expired.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.canceled_expired.text',
          variant: 'error',
          parent: 'canceled',
        },
        {
          type: 'connector',
          key: 'canceled_returned',
          label: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.canceled_returned.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pickup_place_box.canceled_returned.text',
          variant: 'error',
          parent: 'canceled',
        },
      ],
      delivery: [
        {
          type: 'milestone',
          key: 'new',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery.new.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'unpaid',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery.unpaid.label',
          text: 'myAccount.purchaseHistory.orderStatuses.delivery.unpaid.text',
          variant: 'warning',
          parent: 'new',
        },
        {
          type: 'connector',
          key: 'inprogress_payment_complete',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery.inprogress_payment_complete.label',
          text: '',
          variant: 'success',
          parent: 'new',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery.inprogress.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'inprogress_error',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery.inprogress_error.label',
          text: 'myAccount.purchaseHistory.orderStatuses.delivery.inprogress_error.text',
          variant: 'error',
          parent: 'inprogress',
        },
        {
          type: 'connector',
          key: 'inprogress_backordered',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery.inprogress_backordered.label',
          text: 'myAccount.purchaseHistory.orderStatuses.delivery.inprogress_backordered.text',
          variant: 'info',
          parent: 'inprogress',
        },
        {
          type: 'connector',
          key: 'packed',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery.packed.label',
          text: '',
          variant: 'info',
          parent: 'inprogress',
        },
        {
          type: 'milestone',
          key: 'shipping',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery.shipping.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery.delivered.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery.canceled.label',
          text: 'myAccount.purchaseHistory.orderStatuses.delivery.canceled.text',
          variant: 'error',
          parent: '',
        },
        {
          type: 'connector',
          key: 'canceled_returned',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery.canceled_returned.label',
          text: 'myAccount.purchaseHistory.orderStatuses.delivery.canceled_returned.text',
          variant: 'error',
          parent: 'canceled',
        },
      ],
      reservation: [
        {
          type: 'milestone',
          key: 'new',
          label: 'myAccount.purchaseHistory.orderStatuses.reservation.new.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'myAccount.purchaseHistory.orderStatuses.reservation.inprogress.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'inprogress_error',
          label: 'myAccount.purchaseHistory.orderStatuses.reservation.inprogress_error.label',
          text: 'myAccount.purchaseHistory.orderStatuses.reservation.inprogress_error.text',
          variant: 'error',
          parent: 'inprogress',
        },
        {
          type: 'milestone',
          key: 'ready_for_pickup',
          label: 'myAccount.purchaseHistory.orderStatuses.reservation.ready_for_pickup.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'myAccount.purchaseHistory.orderStatuses.reservation.delivered.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'myAccount.purchaseHistory.orderStatuses.reservation.canceled.label',
          text: 'myAccount.purchaseHistory.orderStatuses.reservation.canceled.text',
          variant: 'error',
          parent: '',
        },
      ],
      rxreservation: [
        {
          type: 'milestone',
          key: 'new',
          label: 'myAccount.purchaseHistory.orderStatuses.rxreservation.new.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'myAccount.purchaseHistory.orderStatuses.rxreservation.inprogress.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'inprogress_error',
          label: 'myAccount.purchaseHistory.orderStatuses.rxreservation.inprogress_error.label',
          text: 'myAccount.purchaseHistory.orderStatuses.rxreservation.inprogress_error.text',
          variant: 'error',
          parent: 'inprogress',
        },
        {
          type: 'connector',
          key: 'inprogress_backordered',
          label: 'myAccount.purchaseHistory.orderStatuses.rxreservation.inprogress_backordered.label',
          text: 'myAccount.purchaseHistory.orderStatuses.rxreservation.inprogress_backordered.text',
          variant: 'info',
          parent: 'inprogress',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'myAccount.purchaseHistory.orderStatuses.rxreservation.delivered.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'myAccount.purchaseHistory.orderStatuses.rxreservation.canceled.label',
          text: 'myAccount.purchaseHistory.orderStatuses.rxreservation.canceled.text',
          variant: 'error',
          parent: '',
        },
      ],
      delivery_mp: [
        {
          type: 'milestone',
          key: 'inprogress_payment_complete',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery_mp.inprogress_payment_complete.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'inprogress_payment_complete',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery_mp.inprogress_payment_complete_connector.label',
          text: '',
          variant: 'success',
          parent: 'inprogress_payment_complete',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery_mp.inprogress.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'shipping',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery_mp.shipping.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery_mp.delivered.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'myAccount.purchaseHistory.orderStatuses.delivery_mp.canceled.label',
          text: 'myAccount.purchaseHistory.orderStatuses.delivery_mp.canceled.text',
          variant: 'error',
          parent: '',
        },
      ],
      pharmacy_purchase: [
        {
          type: 'milestone',
          key: 'new',
          label: 'myAccount.purchaseHistory.orderStatuses.pharmacy_purchase.new.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'myAccount.purchaseHistory.orderStatuses.pharmacy_purchase.delivered.label',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'myAccount.purchaseHistory.orderStatuses.pharmacy_purchase.canceled.label',
          text: 'myAccount.purchaseHistory.orderStatuses.pharmacy_purchase.canceled.text',
          variant: 'error',
          parent: '',
        },
      ],
    },
  },
}
