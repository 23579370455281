export default {
  babyclub: {
    babyClubWeekLinksPrefix: '/kartavyhod/',
    babyClubWeekLinksSuffix: '-tyden',
    babyClubMonthLinksPrefix: '/kartavyhod/',
    babyClubMonthLinksSuffix: '-mesic',
    babyClubWeeksThreshold: 4,
    babyClubDictionary: [
      'zeroth',
      'first',
      'second',
      'third',
      'fourth',
      'fifth',
      'sixth',
      'seventh',
      'eight',
      'ninth',
      'tenth',
      'eleventh',
      'twelfth',
      'thirteenth',
      'fourteenth',
      'fifteenth',
      'sixteenth',
      'seventeenth',
      'eighteenth',
      'nineteenth',
      'twentieth',
      'twenty-first',
      'twenty-second',
      'twenty-third',
      'twenty-fourth',
      'twenty-fifth',
      'twenty-sixth',
      'twenty-seventh',
      'twenty-eighth',
      'twenty-ninth',
      'thirtieth',
      'thirty-first',
      'thirty-second',
      'thirty-third',
      'thirty-fourth',
      'thirty-fifth',
      'thirty-sixth',
    ],
  },
}
