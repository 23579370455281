import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import config from '../index.mjs'

export default defineAppConfig({
  adultsOnlyEnabled: false,
  blogCategoryContentOnlyOnFirstPageEnabled: false,
  categoryTitleEnabled: false,
  infoBoxEnabled: false,
  mainPageTextEnabled: false,
  recentArticlesCategoriesEnabled: true,
  recentArticlesCategoriesHPEnabled: true,
  recentArticlesCategoriesIds: [7],
  relatedArticlesSimpleTitleEnabled: false,
  subcategoriesDescriptionOnBottomEnabled: false,
  ...config,
})
