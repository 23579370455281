import type { Route } from 'vue-router'
import type { DiscountData, RouteWithExtraData } from '#build/layers/multi-app/layers/cz-drmax/types/abstract-discounts'

export default async function ({
  route,
  pageFromCurrent = 0,
}: {
  route: RouteWithExtraData | Route,
  pageFromCurrent?: number
}) : Promise<DiscountData> {
  const currentPage = parseInt(<string>route.query.page) || 1
  const page = currentPage + pageFromCurrent
  const pathPattern = route.matched[0]?.path || '/'
  const path = pathPattern.split('/')
    .filter((p: string) => p && !p.startsWith(':')) // filter params
    .join('/')
  const content = {
    withBrands: !pageFromCurrent,
    withCategories: !pageFromCurrent,
    withProducts: true,
  }

  return await $fetch(
    `/api/${path}`,
    {
      method: 'GET',
      query: {
        ...route.query || {},
        ...route.params || {},
        page,
        ...content,
      },
    },
  )
}
