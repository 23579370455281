import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
var validateCzVAT = function validateCzVAT(value) {
  var digitsOnly = /^[0-9]*$/.test(value);
  var inputDigits = value.split("");
  var checkSum = inputDigits.slice(0, 7).reduce(function (accumulator, digit, index) {
    return accumulator + (8 - index) * +digit;
  }, 0);
  var checkSumRemainder = checkSum % 11;
  var checkDigit = (11 - checkSumRemainder) % 10;
  return digitsOnly && +inputDigits[7] === checkDigit;
};
export default defineAppConfig({
  validations: {
    cityAllowedCharacters: function cityAllowedCharacters() {
      return true;
    },
    cityContainsLetter: function cityContainsLetter() {
      return true;
    },
    cityMaxLength: 50,
    companyNameMaxLength: 70,
    crnLength: 8,
    crnMaxLengthLength: 16,
    crnNumbersOnly: function crnNumbersOnly(value) {
      return /^[0-9]+$/.test(value);
    },
    crnValid: function crnValid() {
      return true;
    },
    customerNotesAllowedCharacters: function customerNotesAllowedCharacters() {
      return true;
    },
    emailMaxLength: 50,
    firstLastNameAllowedCharacters: function firstLastNameAllowedCharacters(value) {
      return /^[a-zA-Z-'\s\u00C0-\u00FF\u0100-\u017F]*$/.test(value);
    },
    firstNameMaxLength: 25,
    forbiddenWords: [],
    lastNameMaxLength: 40,
    passwordForbiddenKeywords: ["heslo"],
    postCodeValid: function postCodeValid(value) {
      return /^[0-9]{3}\s?[0-9]{2}$/.test(value);
    },
    postCodeValidByForbiddenCode: function postCodeValidByForbiddenCode() {
      return true;
    },
    postCodeValidByThreeDigits: function postCodeValidByThreeDigits() {
      return true;
    },
    streetAllowedCharacters: function streetAllowedCharacters() {
      return true;
    },
    streetMaxLength: 50,
    streetNumberContainsNumber: function streetNumberContainsNumber() {
      return true;
    },
    streetNumberValid: function streetNumberValid() {
      return true;
    },
    streetValid: function streetValid(value) {
      return /^(.*[^0-9]+)\s(([1-9][0-9]*)\/)?([1-9][0-9]*[a-zA-Z]?)$/.test(value);
    },
    vatIdForbiddenValues: [],
    vatIdForbiddenWords: function vatIdForbiddenWords() {
      return true;
    },
    vatIdMaxLength: 12,
    vatIdMaxLengthValid: function vatIdMaxLengthValid(value) {
      return value.length <= 12;
    },
    vatIdMinLength: 10,
    vatIdMinLengthValid: function vatIdMinLengthValid(value) {
      return value.length >= 10;
    },
    vatIdStartsWith: function vatIdStartsWith(value) {
      return value.substring(0, 2) === "CZ";
    },
    vatIdValid: function vatIdValid(value) {
      if (/^CZ[0-9]{8,10}$/.test(value) || /^CZ699[0-9]{6}$/.test(value)) {
        return true;
      }
      if (value.length === 10) {
        return validateCzVAT(value.substring(2));
      }
      return true;
    }
  }
});