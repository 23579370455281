import { registerNuxtServerInitAction } from '@nsf/core/Store.js'
import { state as _base_alertmessage_state, mutations as _base_alertmessage_mutations, actions as _base_alertmessage_actions } from '@nsf/base/store/alertmessage.js';
import { state as _base_config_state, actions as _base_config_actions, mutations as _base_config_mutations, getters as _base_config_getters } from '@nsf/base/store/config.js';
import { nuxtServerInit as _base_nuxtServerInit } from '@nsf/base/store/index.js';
import { state as _base_megamenu_state, actions as _base_megamenu_actions, mutations as _base_megamenu_mutations, getters as _base_megamenu_getters } from '@nsf/base/store/megamenu.js';
import { state as _base_modal_state, mutations as _base_modal_mutations, getters as _base_modal_getters, actions as _base_modal_actions } from '@nsf/base/store/modal.js';
import { state as _base_navigation_state, actions as _base_navigation_actions, mutations as _base_navigation_mutations, getters as _base_navigation_getters } from '@nsf/base/store/navigation.js';
import { state as _base_pagebuilder_state, mutations as _base_pagebuilder_mutations, getters as _base_pagebuilder_getters } from '@nsf/base/store/pagebuilder.js';
import { state as _base_rating_state, actions as _base_rating_actions, mutations as _base_rating_mutations, getters as _base_rating_getters } from '@nsf/base/store/rating.js';
import { state as _base_resolver_state, mutations as _base_resolver_mutations, getters as _base_resolver_getters } from '@nsf/base/store/resolver.js';
import { TOAST_INFO as _base_toast_TOAST_INFO, TOAST_SUCCESS as _base_toast_TOAST_SUCCESS, TOAST_WARNING as _base_toast_TOAST_WARNING, TOAST_DANGER as _base_toast_TOAST_DANGER, TOAST_SECONDARY as _base_toast_TOAST_SECONDARY, state as _base_toast_state, mutations as _base_toast_mutations, actions as _base_toast_actions, getters as _base_toast_getters } from '@nsf/base/store/toast.js';
import { state as gtm_state, mutations as gtm_mutations, actions as gtm_actions, getters as gtm_getters } from '@nsf/gtm/store/index.js';
import { state as search_state, mutations as search_mutations } from '@nsf/search/store/index.js';
import { state as catalog_filters_state, mutations as catalog_filters_mutations } from '@nsf/catalog/store/filters.js';
import { state as catalog_filtersV2_state, mutations as catalog_filtersV2_mutations, getters as catalog_filtersV2_getters } from '@nsf/catalog/store/filtersV2.js';
import { state as catalog_state, nuxtServerInit as catalog_nuxtServerInit, mutations as catalog_mutations, actions as catalog_actions, getters as catalog_getters } from '@nsf/catalog/store/index.js';
import { state as catalog_reviews_state, mutations as catalog_reviews_mutations, actions as catalog_reviews_actions } from '@nsf/catalog/store/reviews.js';
import { state as apotheka_web_filters_state, getters as apotheka_web_filters_getters, actions as apotheka_web_filters_actions, mutations as apotheka_web_filters_mutations } from '@nsf/apotheka-web/store/filters.js';
import { state as apotheka_web_state } from '@nsf/apotheka-web/store/index.js';
import { state as apotheka_web_map_state, getters as apotheka_web_map_getters, actions as apotheka_web_map_actions, mutations as apotheka_web_map_mutations } from '@nsf/apotheka-web/store/map.js';
import { state as apotheka_web_mappers_state, getters as apotheka_web_mappers_getters, actions as apotheka_web_mappers_actions, mutations as apotheka_web_mappers_mutations } from '@nsf/apotheka-web/store/mappers.js';
import { state as apotheka_web_places_state, getters as apotheka_web_places_getters, mutations as apotheka_web_places_mutations, actions as apotheka_web_places_actions } from '@nsf/apotheka-web/store/places.js';
import { state as apotheka_web_places_pharmacies_state, actions as apotheka_web_places_pharmacies_actions, mutations as apotheka_web_places_pharmacies_mutations } from '@nsf/apotheka-web/store/places/pharmacies.js';
import { state as apotheka_web_userFilters_state, getters as apotheka_web_userFilters_getters, actions as apotheka_web_userFilters_actions, mutations as apotheka_web_userFilters_mutations } from '@nsf/apotheka-web/store/userFilters.js';
import { state as articles_state, mutations as articles_mutations, actions as articles_actions } from '@nsf/articles/store/index.js';
import { state as cms_state, nuxtServerInit as cms_nuxtServerInit, mutations as cms_mutations, actions as cms_actions } from '@nsf/cms/store/index.js';
import { state as delivery_fe_state, nuxtServerInit as delivery_fe_nuxtServerInit, mutations as delivery_fe_mutations, getters as delivery_fe_getters } from '@nsf/delivery-fe/store/index.js';

export default function({ store: _store }) {
  _store.registerModule('_base/alertmessage', { namespaced: true, state: _base_alertmessage_state, mutations: _base_alertmessage_mutations, actions: _base_alertmessage_actions });
  _store.registerModule('_base/config', { namespaced: true, state: _base_config_state, actions: _base_config_actions, mutations: _base_config_mutations, getters: _base_config_getters });
  _store.registerModule('_base', { namespaced: true,  });
  _store.registerModule('_base/megamenu', { namespaced: true, state: _base_megamenu_state, actions: _base_megamenu_actions, mutations: _base_megamenu_mutations, getters: _base_megamenu_getters });
  _store.registerModule('_base/modal', { namespaced: true, state: _base_modal_state, mutations: _base_modal_mutations, getters: _base_modal_getters, actions: _base_modal_actions });
  _store.registerModule('_base/navigation', { namespaced: true, state: _base_navigation_state, actions: _base_navigation_actions, mutations: _base_navigation_mutations, getters: _base_navigation_getters });
  _store.registerModule('_base/pagebuilder', { namespaced: true, state: _base_pagebuilder_state, mutations: _base_pagebuilder_mutations, getters: _base_pagebuilder_getters });
  _store.registerModule('_base/rating', { namespaced: true, state: _base_rating_state, actions: _base_rating_actions, mutations: _base_rating_mutations, getters: _base_rating_getters });
  _store.registerModule('_base/resolver', { namespaced: true, state: _base_resolver_state, mutations: _base_resolver_mutations, getters: _base_resolver_getters });
  _store.registerModule('_base/toast', { namespaced: true, TOAST_INFO: _base_toast_TOAST_INFO, TOAST_SUCCESS: _base_toast_TOAST_SUCCESS, TOAST_WARNING: _base_toast_TOAST_WARNING, TOAST_DANGER: _base_toast_TOAST_DANGER, TOAST_SECONDARY: _base_toast_TOAST_SECONDARY, state: _base_toast_state, mutations: _base_toast_mutations, actions: _base_toast_actions, getters: _base_toast_getters });
  _store.registerModule('gtm', { namespaced: true, state: gtm_state, mutations: gtm_mutations, actions: gtm_actions, getters: gtm_getters });
  _store.registerModule('search', { namespaced: true, state: search_state, mutations: search_mutations });
  _store.registerModule('catalog/filters', { namespaced: true, state: catalog_filters_state, mutations: catalog_filters_mutations });
  _store.registerModule('catalog/filtersV2', { namespaced: true, state: catalog_filtersV2_state, mutations: catalog_filtersV2_mutations, getters: catalog_filtersV2_getters });
  _store.registerModule('catalog', { namespaced: true, state: catalog_state, mutations: catalog_mutations, actions: catalog_actions, getters: catalog_getters });
  _store.registerModule('catalog/reviews', { namespaced: true, state: catalog_reviews_state, mutations: catalog_reviews_mutations, actions: catalog_reviews_actions });
  _store.registerModule('apotheka-web/filters', { namespaced: true, state: apotheka_web_filters_state, getters: apotheka_web_filters_getters, actions: apotheka_web_filters_actions, mutations: apotheka_web_filters_mutations });
  _store.registerModule('apotheka-web', { namespaced: true, state: apotheka_web_state });
  _store.registerModule('apotheka-web/map', { namespaced: true, state: apotheka_web_map_state, getters: apotheka_web_map_getters, actions: apotheka_web_map_actions, mutations: apotheka_web_map_mutations });
  _store.registerModule('apotheka-web/mappers', { namespaced: true, state: apotheka_web_mappers_state, getters: apotheka_web_mappers_getters, actions: apotheka_web_mappers_actions, mutations: apotheka_web_mappers_mutations });
  _store.registerModule('apotheka-web/places', { namespaced: true, state: apotheka_web_places_state, getters: apotheka_web_places_getters, mutations: apotheka_web_places_mutations, actions: apotheka_web_places_actions });
  _store.registerModule('apotheka-web/places/pharmacies', { namespaced: true, state: apotheka_web_places_pharmacies_state, actions: apotheka_web_places_pharmacies_actions, mutations: apotheka_web_places_pharmacies_mutations });
  _store.registerModule('apotheka-web/userFilters', { namespaced: true, state: apotheka_web_userFilters_state, getters: apotheka_web_userFilters_getters, actions: apotheka_web_userFilters_actions, mutations: apotheka_web_userFilters_mutations });
  _store.registerModule('articles', { namespaced: true, state: articles_state, mutations: articles_mutations, actions: articles_actions });
  _store.registerModule('cms', { namespaced: true, state: cms_state, mutations: cms_mutations, actions: cms_actions });
  _store.registerModule('delivery-fe', { namespaced: true, state: delivery_fe_state, mutations: delivery_fe_mutations, getters: delivery_fe_getters });

  if (process.server) {
    registerNuxtServerInitAction('_base', _base_nuxtServerInit);
    registerNuxtServerInitAction('catalog', catalog_nuxtServerInit);
    registerNuxtServerInitAction('cms', cms_nuxtServerInit);
    registerNuxtServerInitAction('delivery-fe', delivery_fe_nuxtServerInit);
  }
}
