export default {
  footer: {
    emailSubscription: false,
    feedatyMerchantId: '',
    feedatyWidget: false,
    flags: true,
    footerCertificateImages: [],
    footerCertificateText: '',
    footerCertificateTextClass: '',
    footerTopAbsoluteImages: [],
    footerTopAbsoluteImagesHeight: 0,
    footerTopLogos: [
      {
        src: '/images/nejduveryhodnejsi-znacka-2024.png',
        alt: 'Nejdůvěryhodnější značka',
        width: 185,
        height: 200,
      },
      {
        src: '/images/seller-of-the-year-2023.png',
        alt: 'Obchodník roku 2023',
        width: 186,
        height: 86,
      },
    ],
    mobileApplications: true,
    paymentsImages: [
      {
        src: '/images/no-responsive-images/payments.svg',
        alt: 'Přijimáme platby: master, visa, dpd, ceska posta, sodexo, up, edenred, ppl',
        width: 444,
        height: 27,
      },
    ],
    suklImages: true,
    useFooterCertificateBlock: false,
  },
}
