const pickupInstructions = [
  {
    icon: 'sms',
    fragments: [{ text: 'pickupSelect.config.packeta.pickupInstructionsSmsText1' }],
  },
  {
    icon: 'pharmacy',
    fragments: [{ text: 'pickupSelect.config.packeta.pickupInstructionsPharmacyText1' }],
  },
]

export default {
  orderLabel: 'pickupSelect.config.packeta.orderLabel',
  unavailablePickupLabel: 'pickupSelect.config.packeta.unavailablePickupLabel',
  selectedPlaceLabel: 'pickupSelect.config.packeta.selectedPlaceLabel',
  confirmButtonLabel: 'pickupSelect.config.packeta.confirmButtonLabel',
  getTitle() {
    return this.title
  },
  getSubtitle() {
    return [this.location.street, this.location.city].filter(Boolean).join(', ')
  },
  getInstructions() {
    return {
      place: this.location.description
        ? [
            {
              icon: 'info',
              fragments: [{ text: this.location.description }],
            },
          ]
        : [],
      pickup: pickupInstructions,
    }
  },
  pins: {
    enabled: 'pin-packeta-enabled.svg',
    disabled: 'pin-packeta-disabled.svg',
    selected: 'pin-packeta-selected.svg',
    loading: 'pin-loading.svg',
  },
  clusterFillColor: '#BA1B02',
  icon: '/img/delivery/packeta.svg',
}
